.map-container {
  width: 60%;
  height: 20rem;
}
@media only screen and (max-width: 600px) {
  .map-container {
    width: 100%;
    height: 20rem;
  }
}
