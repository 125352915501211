.main_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.desc {
  width: 100%;
}
.decs_p {
  font-size: 30px;
}

.image_div {
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../../Assets//HorseKiss.jpeg");

  /* Set a specific height */
  min-height: 300px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image_div p {
  font-family: "Fredoka", sans-serif;
  color: white;
}
.image_div li {
  font-family: "Fredoka", sans-serif;
  color: white;
}
.image_div_title {
  font-size: 3rem;
}
.about_parag_cont {
  font-family: "Fredoka", sans-serif;
  font-size: 20px;
}
.about_parag_cont_title {
  color: rgb(56, 14, 225);
  font-size: 20px;
  width: 100%;
}
.icon {
  width: 100px;
  height: 100px;
}
.icon_cont_title {
  color: rgb(56, 14, 225);
  font-size: 25px;
}
.Icon_cont_aligner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.about_parag_cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.paper_size {
  width: 350;
  height: 350;
}
@media only screen and (max-width: 600px) {
  .image_div {
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../../Assets//HorseKiss.jpeg");

    /* Set a specific height */
    min-height: 300px;
    /* Create the parallax scrolling effect */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desc {
    width: 70%;
  }
  .image_div li {
    font-family: "Fredoka", sans-serif;
    color: white;
    font-size: 1rem;
  }
  .about_parag_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .paper_size {
    width: 300;
  }
}
