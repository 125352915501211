.mobile_swiper_container {
  display: none;
}
@media only screen and (max-width: 800px) {
  .swiper_container {
    display: none;
  }
  .mobile_swiper_container {
    display: flex;
  }
}
