.logo {
  width: 100px;
  height: 100px;
}
.header_list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.header_list_item_div {
  margin-right: 30px;
}
.header_list_item {
  text-decoration: none;
  color: black;
  font-family: "Fredoka", sans-serif;
  font-size: 30px;
}
.header_list_item:hover {
  color: rgb(56, 14, 225);
}

@media only screen and (max-width: 500px) {
  .logo {
    width: 50px;
    height: 50px;
  }
  .header_list {
    display: none;
  }
}
